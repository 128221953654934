/* eslint-disable no-console */
/* jshint esversion: 8 */

import OpenAI from 'openai';

class OpenAiWrapper {
  constructor(initialOpenAiKey, baseURL, app = 'sofatutor') {
    this.openAiKey = initialOpenAiKey;
    this.baseURL = baseURL;
    this.app = app;
    this.keyFetchPromiseRef = null;
    this.openAiClient = null;
    this.customerInfoPromise = null;
    this.csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;

    this.initializeCustomerInfo();
  }

  async proxyRequest(endpoint, method = 'POST') {
    try {
      const response = await fetch(`/api/ai_proxy${endpoint}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.csrfToken
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error(`Error in API request to ${endpoint}:`, error);
      throw error;
    }
  }

  async initializeCustomerInfo() {
    if (!this.customerInfoPromise) {
      this.customerInfoPromise = (async () => {
        try {
          const info = await this.proxyRequest('/customer_info', 'GET');
          console.info('Customer info:', info);
          return info;
        } catch (error) {
          console.error('Error fetching customer info:', error);
          throw error;
        }
      })();
    }
  }

  async getOpenAiClient() {
    if (!this.openAiKey || this.openAiKey.length === 0) {
      await this.getNewOpenAiKey();
    }
    this.openAiClient = new OpenAI({
      apiKey: this.openAiKey,
      dangerouslyAllowBrowser: true,
      baseURL: `${this.baseURL}/v1` // Ensure '/v1' for OpenAI client
    });
    return this.openAiClient;
  }

  async getNewOpenAiKey() {
    if (this.keyFetchPromiseRef) {
      console.debug(
        'Key fetch already in progress, waiting for the existing promise...'
      );
      return this.keyFetchPromiseRef;
    }

    this.keyFetchPromiseRef = (async () => {
      try {
        console.debug('Generating new OpenAI key...');
        const response = await this.proxyRequest('/generate_key');
        if (response.success) {
          this.openAiKey = response.token;
          console.debug('New OpenAI key generated and stored in session');
          return true;
        } else {
          throw new Error('Failed to generate new OpenAI key');
        }
      } catch (error) {
        console.error('Error generating new OpenAI key:', error);
        throw error;
      } finally {
        this.keyFetchPromiseRef = null;
      }
    })();

    return this.keyFetchPromiseRef;
  }

  isAuthOrConnectionError(error) {
    return ['Authentication', 'APIConnection', '401'].some(
      errorType =>
        error.message.includes(errorType) || error.name.includes(errorType)
    );
  }

  async executeWithRetry(operation, maxRetries = 5, initialDelay = 1000) {
    let retries = 0;

    if (!this.openAiKey || this.openAiKey.length === 0) {
      console.debug('Initial API key not set, fetching new key...');
      await this.getNewOpenAiKey();
    }

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    while (retries < maxRetries) {
      try {
        console.debug(`Executing operation... (Attempt ${retries + 1})`);
        const client = await this.getOpenAiClient(); // Ensure client is initialized properly
        return await operation(client);
      } catch (error) {
        console.debug(`Error encountered: ${error.message}`);

        if (retries === maxRetries - 1) {
          console.debug(`Max retries (${maxRetries}) reached. Throwing error.`);
          throw error;
        }

        if (this.isAuthOrConnectionError(error) || !this.openAiKey) {
          console.debug(
            'Authentication error or missing API key, fetching new key...'
          );
          await this.getNewOpenAiKey();
          retries++;
          continue; // Retry operation with new key without delay
        } else {
          console.debug('Retryable error detected, retrying...');
        }

        const delayTime = initialDelay * Math.pow(2, retries);
        console.debug(`Waiting ${delayTime}ms before next retry...`);
        await delay(delayTime);
        retries++;
      }
    }

    throw new Error(`Operation failed after ${maxRetries} retries`);
  }
}

export default OpenAiWrapper;
